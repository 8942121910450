import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  PromptValue,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInfoModel } from '../models/user-info.model';
import { StorageService } from './storage.service';
import { AppRoutes, SocialDomains, StorageNames } from '../constants/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../home/auth.service';
import { ApiEndpoints } from '../constants/api.endpoint';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
  extension_Birthday?: string;
  idp_access_token?: string;
  name?: string;
  family_name?: string;
  extension_PhoneNumber?: string;
};

@Injectable({
  providedIn: 'root',
})
export class Adb2cService implements OnDestroy {
  private msalStatus: string;
  // loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private cookie: CookieService,
    private _storageService: StorageService,
    private httpClient: HttpClient,
    private _authService: AuthService
  ) {}

  public initiateMsalAuthentication() {
    if (
      this.msalStatus === undefined ||
      this.msalStatus === InteractionStatus.None
    ) {
      this.msalAccountAdded();
      this.msalInProgress();
      this.msalSuccess();
      this.msalFailed();
    }
  }

  public tempTrySuccess() {
    if (
      this.msalStatus === undefined ||
      this.msalStatus === InteractionStatus.None
    ) {
      this.msalSuccess();
      this.msalFailed();
    }
  }

  private msalAccountAdded() {
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          this.router.navigate(['/login']);
        }
      });
  }

  private msalInProgress() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => {
          this.msalStatus = status;
          return status === InteractionStatus.None;
        }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }
  private msalSuccess() {
    // Check If it is Login;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        // console.log('payload', payload);
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        let isGoogleSignIn = false;
        if (idtoken.idp && idtoken.idp == SocialDomains.Google) {
          isGoogleSignIn = true;
        }
        const userInfoData = new UserInfoModel();
        userInfoData.user_id = idtoken.oid ?? '';
        userInfoData.email = idtoken.emails[0] ?? '';
        userInfoData.first_name = idtoken.name ?? '';
        userInfoData.last_name = idtoken.family_name ?? '';
        userInfoData.phone_no = idtoken.extension_PhoneNumber ?? '';
        userInfoData.token = payload.idToken ?? '';
        userInfoData.birth_date = idtoken.extension_Birthday ?? '';
        userInfoData.isLoggedIn = true;
        const authority = payload.authority;

        // For Social Login+SignIn
        if (isGoogleSignIn) {
          this._storageService.saveStorage(
            StorageNames.isGoogleSignIn,
            isGoogleSignIn
          );
          if (idtoken.idp_access_token) {
            this._storageService.saveStorage(
              StorageNames.idpAccessToken,
              idtoken.idp_access_token
            );
          }

          // console.log('Google SignIn');
          // Creating payload as per existing registration
          const registerationData = {
            user_name: 'fan' + idtoken.oid.substring(0, 8), // send oid first 8 characters as user_name said by Sunny
            user_password: '',
            first_name: userInfoData.first_name,
            last_name: userInfoData.last_name,
            user_mobile: userInfoData.phone_no,
            user_details: {
              user_email: userInfoData.email,
              birth_date: userInfoData.birth_date,
            },
            referrer: '',
            user_type: 'fan',
          };
          this.registerUserIntoDb(registerationData);
          // Save data into local storage
          this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
          this.getUseIdFromDbAndSaveInStorage(userInfoData, false);
        }
        // For Sign Up and
        else if (
          authority
            .toLowerCase()
            .includes(environment.b2cPolicies.names.signUp.toLowerCase())
        ) {
          // Creating payload as per existing registration
          const registerationData = {
            user_name: 'fan' + idtoken.oid.substring(0, 8), // send oid first 8 characters as user_name said by Sunny
            user_password: '',
            first_name: userInfoData.first_name,
            last_name: userInfoData.last_name,
            user_mobile: userInfoData.phone_no,
            user_details: {
              user_email: userInfoData.email,
              birth_date: userInfoData.birth_date,
            },
            referrer: '',
            user_type: 'fan',
          };
          this.registerUserIntoDb(registerationData);
          this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
        } else {
          // console.log('Only SignIn');
          // Sign In
          // Save data into local storage
          this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
          this.getUseIdFromDbAndSaveInStorage(userInfoData);
        }
        // this.msalService.instance.setActiveAccount(payload.account);

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          idtoken.acr === environment.b2cPolicies.names.editProfile ||
          idtoken.tfp === environment.b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = this.msalService.instance
            .getAllAccounts()
            .find(
              (account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid &&
                account.idTokenClaims?.sub === idtoken.sub &&
                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr ===
                  environment.b2cPolicies.names.signIn ||
                  (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp ===
                    environment.b2cPolicies.names.signIn)
            );

          let signInFlowRequest: SsoSilentRequest = {
            authority: environment.b2cPolicies.authorities.signIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signIn policy
          this.msalService.ssoSilent(signInFlowRequest);
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (
          idtoken.acr === environment.b2cPolicies.names.resetPassword ||
          idtoken.tfp === environment.b2cPolicies.names.resetPassword
        ) {
          let signInFlowRequest: RedirectRequest | PopupRequest = {
            authority: environment.b2cPolicies.authorities.signIn.authority,
            scopes: [...environment.apiConfig.scopes],
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };
          this.login(signInFlowRequest);
        }
        return result;
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  signUp() {
    let signUpFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.signUp.authority,
      scopes: [...environment.apiConfig.scopes],
    };
    this._storageService.saveStorage(StorageNames.IsVerifyCompleted, false);
    this.login(signUpFlowRequest);
  }

  private msalFailed() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority:
              environment.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };
          this.login(resetPasswordFlowRequest);
        } else if (
          result.error &&
          result.error.message.includes('AADB2C90091')
        ) {
          // Handle cancellation (User canceled the flow)
          this.router.navigate(['/']);
        } else {
          // Other errors (fallback)
          console.error('Login error:', result.error);
          this.router.navigate(['/error']);
        }
      });
  }

  logout() {
    const currentToken = this._storageService.getStorage(
      StorageNames.DeviceToken
    );
    const userId = this._storageService.getStorage(StorageNames.UserId);
    this.unSubscribeDeviceToken(currentToken, userId).subscribe();
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
      const isGoogleSignIn = this._storageService.getStorage(
        StorageNames.isGoogleSignIn
      );
      let newTab = null;

      if (isGoogleSignIn && isGoogleSignIn === 'true') {
        // const idpAccessToken = this._storageService.getStorage(
        //   StorageNames.idpAccessToken
        // );
        // console.log('idpAccessToken', idpAccessToken);
        // debugger;
        // if (idpAccessToken) {
        //   this.httpClient
        //     .post(
        //       `https://oauth2.googleapis.com/revoke?token=${idpAccessToken}`,
        //       {}
        //     )
        //     .subscribe((res) => {
        //       debugger;
        //       console.log(`Google token revoke: ${res}`);
        //     });
        // }

        // In order to Logout from Social Login Google,
        // we need to open https://accounts.google.com/Logout
        newTab = window.open('https://accounts.google.com/Logout', '_blank');
        // Attempt to focus on the new tab (this may not work in all browsers)
        // After 0.5 second
        setTimeout(() => {
          if (newTab) {
            console.log('time over- close tab');
            // Close the tab as Social Sigout is completed
            newTab.close();
          }
        }, 500); // Delay for Google logout to complete first
      }
    }
  }

  editProfile() {
    let editProfileFlowRequest: RedirectRequest | PopupRequest = {
      authority: environment.b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };

    this.login(editProfileFlowRequest);
  }

  private getUserId(userInfo: UserInfoModel): Observable<any> {
    const payload = {
      user_name: userInfo.email,
    };
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');

    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.LogADUser}`,
      payload,
      options
    );
  }
  public getUserByEmail(userInfo: UserInfoModel): Observable<any> {
    const payload = {
      user_email: userInfo.email.toLocaleLowerCase(),
    };
    const headersData = new HttpHeaders({
      Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'application/json',
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.GetUserByEmail}`,
      payload,
      options
    );
  }
  public saveDeviceToken(deviceToken: string, userId: string): Observable<any> {
    console.log('userId in saveDeviceToken()', userId);
    // const payload = {
    //   //   user_name: userInfo.email,
    // };
    const headersData = new HttpHeaders({
      // Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'application/json',
      deviceId: deviceToken,
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UpdateDevieToken}/${userId}`,
      // payload, //     // Don't send empty payload, it will through error in API call
      {},
      options
    );
  }

  registerUserIntoDb(registerationData) {
    // Call API to register user in MongoDb as well
    this._authService
      .register(registerationData, registerationData.user_type)
      .subscribe((result) => {
        //if registration success
        if (result !== 'exist') {
          // redirect to Login so that User can Login
          this.login();
        } else {
          //registration failed error
          console.log('User is already there!');
        }
        //
      });
  }

  getUseIdFromDbAndSaveInStorage(
    userInfoData: UserInfoModel,
    isRedirectRequired: boolean = true
  ) {
    // Get User_id from API and save in cookie
    this.getUserId(userInfoData).subscribe((dbUserId) => {
      // Check If user is not registered in Mongo db
      if (dbUserId == 'No') {
        // Register User into db
        const registerationData = {
          user_name: 'fan' + userInfoData.user_id.substring(0, 8), // send oid first 8 characters as user_name said by Sunny
          user_password: '',
          first_name: userInfoData.first_name,
          last_name: userInfoData.last_name,
          user_mobile: userInfoData.phone_no,
          user_details: {
            user_email: userInfoData.email,
            birth_date: userInfoData.birth_date,
          },
          referrer: '',
          user_type: 'fan',
        };
        this.registerUserIntoDb(registerationData);
        // Save data into local storage
        this._storageService.saveStorage(StorageNames.UserInfo, userInfoData);
        this.getUseIdFromDbAndSaveInStorage(userInfoData, false);
        this.getUserId(userInfoData);
      }

      this.cookie.set(StorageNames.UserId, dbUserId, 7, '/');
      // get device token
      const currentToken = this._storageService.getStorage(
        StorageNames.DeviceToken
      );
      if (currentToken) {
        // save device token to db
        this.saveDeviceToken(currentToken, dbUserId).subscribe((res) => {
          console.log('token saved in API res', res);
        });
      }
      // Navigate to dashboard once all done
      let googleSignInStatus = this._storageService.getStorage(
        StorageNames.isGoogleSignIn
      );
      if (!googleSignInStatus) {
        this.getUserByEmail(userInfoData).subscribe((res) => {
          console.log('user details', res);

          if (isRedirectRequired && res.user_profile) {
            this.router.navigate([`/explore`]);
          } else {
            this.router.navigate([`/interest`]);
          }
        });
      }
    });
  }
  public unSubscribeDeviceToken(
    deviceToken: string,
    userId: string
  ): Observable<any> {
    const headersData = new HttpHeaders({
      // Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'application/json',
      deviceId: deviceToken,
    });
    const options = {
      headers: headersData,
    };
    return this.httpClient.post(
      `${environment.dev}/${ApiEndpoints.UnsubscribeDeviceToken}/${userId}`,
      {},
      options
    );
  }

  public isUserLoggedIn() {
    const token = this.msalService.instance.getActiveAccount()?.idToken;
    if (token && this.msalService.instance.getAllAccounts().length > 0) {
      return true;
    } else {
      return false;
    }
  }
  public checkLoginAndRedirect() {
    const userInfo: UserInfoModel = JSON.parse(
      this._storageService.getStorage(StorageNames.UserInfo)
    );
    if (this.isUserLoggedIn()) {
      this.router.navigate([`/${AppRoutes.Explore}`]);
    } else if (
      this.msalStatus === InteractionStatus.None ||
      this.msalStatus == undefined
    ) {
      this.router.navigate([`/`]);
    } else {
      this.router.navigate([`/${AppRoutes.Authenticate}`]);
    }
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
