import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { Md5Hasher } from '../core/util/helpers/md5-hasher';
import { BaseService } from '../services/base.service';
import { AnalyticEventService } from '../core/services/analytic-event.service';

@Injectable({
  providedIn: 'any',
})
export class AuthService extends BaseService {
  ENDPOINT: string;

  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private analytics: AnalyticEventService
  ) {
    super();
    this.ENDPOINT = this.server;
  }

  static LOGIN = 'log/';

  login(username: string, password: string): Observable<any> {
    const data = {
      user_name: username,
      user_password: Md5Hasher.hash(password),
    };

    //console.log(data.user_password);

    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http
        .post(this.ENDPOINT + AuthService.LOGIN, data, options)
        .subscribe((val) => {
          if (val && val !== 'No') {
            this.analytics.emitLogIn();
            this.cookie.set('user_id', val as string, 7, '/');
          }
          observer.next(val);
        });
    });
  }

  register(input: any, user_type: string = 'fan'): Observable<any> {
        // Split the date string into parts
        const [month, day, year] = input.user_details.birth_date.split('-').map(Number);

        const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

    const data = {
      user_name: input.user_name.toLowerCase(),
      user_password: input.user_password,
      first_name: input.first_name,
      last_name: input.last_name,
      user_mobile: input.user_mobile,
      user_email: input.user_details.user_email.toLowerCase(),
      birthday: formattedDate
        ? new Date(formattedDate).toISOString(): '',
      referrer: input.referrer ? input.referrer : '',
      user_type: user_type,
    };

    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http
        .post(this.ENDPOINT + AuthService.LOGIN + 'register', data, options)
        .subscribe((val) => {
          if (val) {
            let referrer = 'web';
            if (data.referrer) {
              referrer = data.referrer;
            }

            this.analytics.emitSignUp(referrer);
          }
          observer.next(val);
        });
    });
  }

  getUserLanding(userName: String): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http
        .get(this.ENDPOINT + 'users/landing/' + userName, options)
        .subscribe((val) => {
          observer.next(val);
        });
    });
  }

  CreateVerification(emailAddr: string, vType: string): Observable<any> {
    const data = {
      email: emailAddr,
      platform: 'web',
    };

    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return new Observable((observer) => {
      this.http
        .post(
          this.ENDPOINT + AuthService.LOGIN + 'verify/type/' + vType,
          data,
          options
        )
        .subscribe((val) => {
          observer.next(val);
        });
    });
  }

  ResendVerification(vId: string): Observable<any> {
    const data = {
      platform: 'web',
    };

    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return new Observable((observer) => {
      this.http
        .post(
          this.ENDPOINT + AuthService.LOGIN + 'verify/' + vId,
          data,
          options
        )
        .subscribe((val) => {
          observer.next(val);
        });
    });
  }
  CheckVerification(
    vCode: string,
    vId?: string,
    email?: string
  ): Observable<any> {
    const uri = `${this.ENDPOINT}${AuthService.LOGIN}verify/mob/code/${vCode}`;
    const data = {
      user_email: email,
    };
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    if (vId) {
      return this.http.post(uri, data, options);
    }
  }

  postProfileMedia(file: File, type: string, uId: string) {
    if (file === undefined) {
      return new Observable((obs) => {
        obs.next(undefined);
        obs.complete();
      });
    }
    const data = new FormData();
    data.append('file', file);
    return this.http.post(this.ENDPOINT + 'media/' + uId + '/' + type, data);
  }

  postUserAvailability(val: string, type: string) {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    const data = type == 'email' ? { user_email: val } : { user_name: val };
    //console.log(data);
    return this.http.post(this.ENDPOINT + 'log/check/' + type, data, options);
  }

  postUserAvailabilityAllExpress(val: any) {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    //console.log(data);
    return this.http.post(
      this.ENDPOINT + 'log/check/all/express',
      val,
      options
    );
  }

  postSettingUpdate(
    userId: string,
    settingType: string,
    updateJson: any
  ): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http
        .post(
          this.ENDPOINT + 'setting/' + userId + '/' + settingType,
          updateJson,
          options
        )
        .subscribe((val) => {
          if (val) {
            observer.next(val);
          }
        });
    });
  }

  getAllStatesLocations() {
    return {
      states: [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' },
      ],
    };
  }

  verifyEmailAddress(email: string): Observable<any> {
    const httpHead = new HttpHeaders();
    const data = {
      user_email: email,
    };
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.http.post(this.ENDPOINT + 'verify/PostById', data, options);
  }

  completeProfile(formData: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.http.post(this.ENDPOINT + 'users/UpdateUserProfile', formData, options);
  }
  
}
