import { ApiService } from './api.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from './storage.service';
import { StorageNames } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class PostsService extends ApiService {

  static USER = 'users/';
  static POST = 'feed/';
  

  constructor(private http: HttpClient, private cookie: CookieService, private _storageService: StorageService) {
    super();
    this.ENDPOINT
  }

  getFeed(userId: string, segment: number): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + this.POST + userId + '/' + segment, options);
  }

  //get interest feed 
  getInterestFeed(userId: string, interest: string, segment: number): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'interest/' + userId + '/' + interest + '/' + segment, options);
  }

  //get suggested influencers 
  getCelebs(userId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + "search/" + userId + "/celeb", options);
  }

  //get interestlist
  getInterestList(): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + "interestlist", options);
  } 

  //get content
  getPostContent(userId: string, postId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.http.get(this.ENDPOINT + 'feed/single/' + postId + '/' + userId, options)
  }

  //delete content 
  deletePostContent(contentId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.delete(this.ENDPOINT + 'content/' + contentId, options);
  }

  //post images/ video/ content
  //post json format media files
  postJsonMedia(postJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.http.post(this.ENDPOINT + this.MEDIA_JSON_UPLOAD, postJson, options);
  }

  postContent(postJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.http.post(this.ENDPOINT + this.CONTENT_UPLOAD, postJson, options);
  }

  //get post comments
  getCommentsForPost(userid: string, postId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'comments/' + userid + '/' + postId, options);
  }

  getCommentsPage(userid: string, postId: string, segment: number): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'comments/' + userid + '/' +postId + '/page/' + segment, options);
  }

  //get single comment

  //post comment
  postComment(commentJson: any): Observable<any> {
    /*
    var newComment = {  
        content_id: postid,
        user_id: userid,  
        user_name: user_name, 
        user_image: user_image,  
        comment_text: text,
        comment_date: now, 
        comment_type: "text",
        media_type: "",
        comment_image: "",
        comment_status: "visible",
        comment_id: comId,
        replies: [],
        likes: []
    };
    */
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return this.http.post(this.ENDPOINT + 'comments', commentJson, options);
  }

  //delete comment
  deleteComment(commentId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.delete(this.ENDPOINT + 'comments/' + commentId, options);
  }

  //post content like 
  postContentLike(userId: string, postId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.http.post(this.ENDPOINT + 'like/post/' + postId + '/' + userId, undefined, options);
  }

  //post comment like
  postCommentLike(userId: string, commentId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false
    };

    return this.http.post(this.ENDPOINT +  'like/comment/' + commentId + '/' + userId, undefined);
 
  }

  //delete comment like
  deleteLike(likeId: string, contentType: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.http.delete(this.ENDPOINT + 'like/' + likeId + '/' + contentType, options);
  }

  //get comment replies
  getCommentReplies(userid: string, commentId: string, segment: number): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.http.get(this.ENDPOINT + 'comments/replys/' + userid + '/' +  commentId + '/page/' + segment, options);
  }

  //post comment reply
  postReply(commentId: string, replyJson: any, isEdit: boolean): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    let link = this.ENDPOINT + 'comments/reply/' + commentId;
    if (isEdit) {
      link = this.ENDPOINT + 'comments/reply/' + commentId + '/update';
    }

    return this.http.post(link, replyJson, options);
  }

  deleteReply(commentId: string){
    return new Observable((observer) => {
      this.http.delete(this.ENDPOINT + 'comments/reply/' + commentId).subscribe((res)=>{
        observer.next(res);
      }, (error)=> {
        observer.error(error);
      });
    });
  }

  //post reply like
  postReplyLike(replyId: string, likeJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    let link = this.ENDPOINT + 'like/reply/' + replyId + '/vote/up';

    return new Observable((observer) => {
      this.http.post(link, likeJson, options);
    });
  }

  //report content comment
  postAbuseReport(type: string, id: string, user: any): Observable<any> {

    let data = {
        item_type: type,
        item_id: id,  
        review_count: 0,
        report_count: 0,
        reported_by: [user]
    };  

    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.http.post(this.ENDPOINT + 'abuse', data, options);

    /*return new Observable((observer) => {
      
    });*/
  } 

  postContentCharge(chargedata: any) : Observable<any> { 
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.http.post(this.ENDPOINT + 'charge', chargedata, options);
  }

  //get entourage
  getAllGroups(): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'group', options);
  }

  getGroup(userId: string, groupId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'group/' + userId + '/' + groupId, options);
  }

  getGroupPosts(groupId: string, segment: number): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'group/' + groupId + 'topics/' + segment, options);
  }

  getUserGroups(userId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'group/user/' + userId, options);
  }

  //join entourage
  postGroupMember(groupId: string, memberJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'group/' + groupId + '/join', memberJson, options)
      .subscribe(
        (val) => {
          if (val) {
            observer.next(val);
          }
        });
    });
  }

  deleteGroupMember(groupId: string, userid: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.delete(this.ENDPOINT + 'group/' + groupId + '/' + userid)
      .subscribe(
        (val) => {
          if (val) {
            observer.next(val);
          }
        });
    });
  }

  //get notifications list
  getNotifications(userId: string): Observable<any> {
    // Check if used is allowed for Push Notifications
    let isGranted = this._storageService.getStorage(StorageNames.isNotificationGratedByUser) ?? '';
    // If push notifications not granted don't do api call
    if(isGranted !='' && JSON.parse(isGranted) == 'false'){
      return null;
    }
    // 
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'PushNotification/GetFCMHistory/' + userId, options);
  }

  //post update read notification 
  updateNotification(FCMId: string, userId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    httpHead.set('MessageId',FCMId);
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'UpdateFCMHistory/' + userId, options);
    });
  }

  getActivityAlert(userId: string, activityType: string = "interest"): Observable<any>{
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'alerts/' + userId + '/activity/' + activityType, options);
    }); 
  }

  //get profile
  getUserWall(userId: string, profileId: string): Observable<any> {
    //type parameter can be 'followers' or 'following'
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'wall/' + profileId + '/' + userId, options);
  }

  getWallPosts(userId: string, profileId: string, segment: number): Observable<any> {
    //type parameter can be 'followers' or 'following'
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'wall/' + profileId + '/' + userId + '/' + segment, options);
  }

  //check follow
  //check card on file
  getCardStatus(userId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'charge/' + userId, options);
  }

  //get followers /following 
  getFollow(userId: string, type: string): Observable<any> {
    //type parameter can be 'followers' or 'following'
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'follow/' + userId + '/' + type, options);
  }

  //pay to follow
  postPaidFollow(userId: string, followJson: any): Observable<any> {
    /*
    const follow = {
      follower_name: logged.user_name,
      follower_image: ogged.user_image,
      follower_id: logged.user_id,
      followed_name: profile.user_name,
      followed_image: profile.user_image,
      followed_id: profile.user_id,
      follow_type: 0,
      status: "active"
    };
    */

    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + "follow/" + userId, followJson, options)
        .subscribe(
          (val) => {
            if (val) {
              observer.next(val);
            }
          });
    });
  }

  //delete to follow
  deleteFollow(userId: string, profileId: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.delete(this.ENDPOINT + 'follow/' + userId + '/' + profileId, options)
        .subscribe(
          (val) => {
            if (val) {
              observer.next(val);
            }
          });
    });
  }


  //post feedback / contact form
  postFeedback(feedbackJson: any): Observable<any> {
    
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    
    return this.http.post(this.ENDPOINT + 'feedback', feedbackJson, options);
  }

  //settings
  getCardUpdateLink(userId: string) {
    //type parameter can be 'followers' or 'following'
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'charge/link/' + userId, options);
  }

  postSettingUpdate(userId: string, settingType: string, updateJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'setting/' + userId + '/' + settingType, updateJson, options)
        .subscribe(
          (val) => {
            if (val) {
              observer.next(val);
            }
          });
    });
  }

  postUserBlock(userId: string, blockType: string, blockJson: any): Observable<any>{
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'setting/' + userId + '/blocks/' + blockType, blockJson, options)
        .subscribe(
          (val) => {
            if (val) {
              observer.next(val);
            }
          });
    });
  }

  getUserBlocks(userId: string) {
    //type parameter can be 'followers' or 'following'
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'setting/' + userId + '/blocks', options);
  }


  postUserInterest(userId: string, updateJson: any): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'interest/' + userId, updateJson, options)
        .subscribe(
          (val) => {
            if (val) {
              observer.next(val);
            }
          });
    });
  }

  postMedia(files: Array<any>) : Observable<any> {
    if (files === undefined) {
      return new Observable((obs) => {
        obs.next(undefined);
        obs.complete();
      });
    }

    /*
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    */
    //console.log('create formdata ' + '[' + Date.now() + '] ');
    const data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append(files[i].name + '.' + files[i].type, files[i]._file, files[i].name);
      //console.log(files[i].name + '.' + files[i].type, files[i]._file, files[i].name);
    }
    //console.log('files appended ' + '[' + Date.now() + '] ');
     
    return this.http.post(this.ENDPOINT + 'media', data);
  }

  postMediaJson(jsonObj: any) : Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return this.http.post(this.ENDPOINT + this.MEDIA_UPLOAD + 'json', jsonObj);
  }

  postProfileMedia(file: File, type: string, uId: string) {
    if (file === undefined) {
      return new Observable((obs) => {
        obs.next(undefined);
        obs.complete();
      });
    }
    const data = new FormData();
    data.append('file', file);
    return this.http.post(this.ENDPOINT + this.MEDIA_UPLOAD + uId + '/' + type, data);
  }

  //get system announcements
  getAnnouncements(): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'alerts/m/announced/notes', options);
  }



  getBookmarkCollection(collectionId: string) : Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'bookmarks/collections/' + collectionId, options);
  }

  getBookmarkFeed(profileid: string, type: string, loggeduserid: string) : Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'bookmarks/' + profileid + '/' + type + '/' + loggeduserid, options);
  }

  postBookmark(bookmarkJson: any){
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };
    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'bookmarks', bookmarkJson, options)
        .subscribe(
          (val) => {
            if (val) {
              observer.next(val);
            }
          });
    });
  }

  getCreateOpts(userid: string) : Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.get(this.ENDPOINT + 'create/' + userid, options);
  }


  CreateVerification(uId: string): Observable<any>{
    const data = {
      user_id: uId,
      email: '',
      platform : 'web'
    };

    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: false,
    };

    return new Observable((observer) => {
      this.http.post(this.ENDPOINT + 'log/verify/type/' + 'password', data, options)
        .subscribe(
          (val) => { 
            observer.next(val);
          });
    });  
  }

  /*
  deletePostContent(contentId: string): Observable<any> {
    const httpHead = new HttpHeaders();
    httpHead.set('Content-Type', 'application/json');
    const options = {
      headers: httpHead,
      reportProgress: true,
    };
    return this.http.delete(this.ENDPOINT + 'content/' + contentId, options);
  }
  */

  

}
