export enum StorageNames {
  UserId = 'user_id',
  UserInfo = 'user-info',
  IsDeviceIdAlreadySaved = 'is-device-id-already-saved',
  RecentNotificationsData = 'recent-notifications-data',
  DeviceToken = 'device-token',
  IsVerifyCompleted = 'is-verify-completed',
  isGoogleSignIn = 'is-google-sign-in',
  idpAccessToken = 'idp-access-token',
  deepLink='deep-link',
  isNotificationGratedByUser='is-notification-granted-by-user',
  isNotificationInteracted='is-notification-interacted',
}

export enum SocialDomains {
  Google = 'google.com',
}
export enum AppRoutes {
  Authenticate = 'authenticate',
  Explore = 'explore',
  Interest = 'interest',
}